// @ts-ignore
import JsonSocket from '@42ideas/jcconnectweb';
import callController from './CallController';

class ConnectionController {
    jsonSocket: any;
    keepAliveInterval?: ReturnType<typeof setInterval>;

    loginToken: string = '';
    chatRoomId: string = '';
    ownMemberId: string = '';
    ownStreamId: string = '';

    otherMemberId: string = '';
    otherStreamId: string = '';

    onConnected?: (() => void);
    onDisconnected?: (() => void);

    constructor() {
        this.jsonSocket = new JsonSocket();
        this.jsonSocket.setOnConnected(() => {
            if (this.onConnected) {
                this.onConnected();
            }
        })
        this.jsonSocket.setOnDisconnected(() => {
            if (this.onDisconnected) {
                this.onDisconnected();
            }
            if (this.keepAliveInterval) {
                clearInterval(this.keepAliveInterval);
            }
            setTimeout(() => {
                this.connect();
            }, 1500);
        });
        this.jsonSocket.setOnUnknownCommand((requestId: number, command: string, params: any) => {
            this.jsonSocket.sendResponse(requestId, -10, {errorMsg: 'Unknown command'});
        });
        this.jsonSocket.registerCommand('messageFromChatRoomMember', async (requestId: number, command: string, params: any) => {
            let message = params.message;
            console.log('messageFromChatRoomMember', message);
            if (message) {
                if (message.type === 'offer') {
                    let answer = await callController.createAnswer(message.streamId, message.offer);
                    let sentResult = await this.sendMessageToChatRoomMember(params.fromMemberId, {
                        type: 'answer',
                        answer: answer,
                        streamId: message.streamId,
                    });
                } else if (message.type === 'answer') {
                    await callController.receivedAnswer(message.streamId, message.answer);
                } else if (message.type === 'iceCandidate') {
                    await callController.receivedIceCandidate(message.streamId, message.candidate);
                } else if (message.type === 'shareScreenOffer') {
                } else if (message.type === 'shareScreenAnswer') {
                } else if (message.type === 'shareScreenClosed') {
                } else if (message.type === 'requestSharedScreen') {
                } else if (message.type === 'closed') {
                    callController.closed();
                }
            }
            this.jsonSocket.sendResponse(requestId, 0, {});
        });
    }

    connect() {
        console.log('connect to', process.env.REACT_APP_WSS_API_URL);
        this.jsonSocket.connect(process.env.REACT_APP_WSS_API_URL);
    }

    async enterChatRoomWithShortCode(serviceCode: string) {
        let result = await connectionController.sendCommand('enterChatRoomWithShortCode', {shortCode: serviceCode})

        console.log('enterChatRoomWithShortCode', result);

        if (result.errorCode === 0) {
            callController.turnUsername = result.turnUsername;
            callController.turnPassword = result.turnPassword;

            this.ownStreamId = result.ownStreamId;
            this.ownMemberId = result.ownMemberId;
            this.loginToken = result.loginToken;
            this.chatRoomId = result.chatRoomId;

            if (result.members.length === 1) {
                this.otherMemberId = result.members[0].memberId;
                this.otherStreamId = result.members[0].streamId;
            }

            console.log('otherMemberId', this.otherMemberId);

            this.keepAliveInterval = setInterval(() => {
                this.jsonSocket.sendCommand('keepAlive', {}, (result: any) => {
                    console.log('sent keepAlive', result);
                });
            }, 55000);

            return true
        }
        else {
            return false;
        }
    }

    async callOtherMember() {
        let offer = await callController.createOffer(this.otherStreamId);
        let sentResult = await this.sendMessageToChatRoomMember(this.otherMemberId, {type: 'offer', offer: offer, streamId: this.otherStreamId});
        console.log('callOtherMember', sentResult);
    }

    async closeCall() {
        await callController.stopStream();
        await this.sendMessageToChatRoomMember(this.otherMemberId, {type: 'closed'});
    }

    async sendCommand(command: string, params: any): Promise<any> {
        return new Promise<any>((fulfilled) => {
            this.jsonSocket.sendCommand(command, params, (result: any) => {
                fulfilled(result);
            });
        });
    }

    async sendMessageToChatRoomMember(memberId: string, message: any) {
        return connectionController.sendCommand('sendMessageToChatRoomMember', {
            chatRoomId: this.chatRoomId,
            memberId: memberId,
            message: message
        });
    }
}

const connectionController = new ConnectionController();
export default connectionController;
