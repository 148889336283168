import React, {CSSProperties} from 'react';
import './StdButton.css';

interface IProps {
    text: string;
    style?: CSSProperties;
    onClick: () => void;
    inverted?: boolean;
}

const StdButton: React.FC<IProps> = (props: IProps) => {
    const textColor = props.inverted ? '#0055A2' : '#ffffff';
    const backgroundColor = props.inverted ? '#ffffff' : '#0055A2';
/*<div onClick={props.onClick} className={"StdButton"} style={{...{whiteSpace: 'pre-line', userSelect: 'none', cursor: 'pointer', color: textColor, borderWidth: 1, backgroundColor: backgroundColor, borderStyle: 'solid', padding: 12, borderRadius: 8, fontWeight: 'bold', fontSize: 18}, ...props.style}}>{props.text}</div>*/
    return (
        <div onClick={props.onClick} className={"StdButton" + (props.inverted ? 'Inverted' : '')} style={{...{whiteSpace: 'pre-line', userSelect: 'none', cursor: 'pointer', borderWidth: 1, borderStyle: 'solid', padding: 12, borderRadius: 8, fontWeight: 'bold', fontSize: 18}, ...props.style}}>{props.text}</div>
    );
}

export default StdButton;
