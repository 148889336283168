import React, {CSSProperties} from 'react';

interface IProps {
    children?: React.ReactNode;
    style?: CSSProperties;
    type: 'header' | 'normalText' | 'boldText'
}

const StdText: React.FC<IProps> = (props: IProps) => {

    let textStyle: CSSProperties = {};
    if (props.type === 'header') {
        textStyle = {color: '#0055A2', fontSize: 24, fontWeight: 'bold'}
    }
    if (props.type === 'boldText') {
        textStyle = {color: '#0055A2', fontSize: 16, fontWeight: 'bold'}
    }
    if (props.type === 'normalText') {
        textStyle = {color: '#0055A2', fontSize: 16}
    }

    return (
        <span style={{...textStyle, ...props.style}}>{props.children}</span>
    );
}

export default StdText;
