import EventEmitter from 'events';
import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({length: 12});



export class CallController extends EventEmitter {

    public turnUsername: string|undefined;
    public turnPassword: string|undefined;
    localMediaStream: MediaStream | undefined;

    peerConnections: {[id: string]: RTCPeerConnection} = {};
    remoteCandidates: {connectionId: string; iceCandidate: any}[] = [];

    getIceConfiguration() {
        return {
            'iceServers': [
                {urls: 'stun:turn.vitus-app.com:3478'},
                {
                    urls: 'turn:turn.vitus-app.com:3478',
                    username: this.turnUsername,
                    credential: this.turnPassword,
                }
            ]
        };
    }

    closed() {
        this.emit('callClosed', {});
    }

    createPeerConnection(connectionId: string) {
        const connection = new RTCPeerConnection(this.getIceConfiguration());
        this.peerConnections[connectionId] = connection;

        const remoteStream = new MediaStream();
        this.emit('remoteStream', {connectionId: connectionId, remoteStream: remoteStream});

        connection.addEventListener('icecandidate', event => {
            //console.log('listener icecandiate');
            this.emit('icecandidate', {connectionId: connectionId, candidate: event.candidate});
        });
        connection.addEventListener('connectionstatechange', event => {
            console.log('listener connectionstatechange', connection.connectionState);
        });
        connection.addEventListener('iceconnectionstatechange', event => {
            console.log('listener iceconnectionstatechange', connection.iceConnectionState);
        });
        connection.addEventListener('track', event => {
            console.log('track remoteMedia!', event.track.kind);
            this.emit('remoteStreamTrack', {connectionId: connectionId, track: event.track});
        });
        return connection;
    }

    async createOffer(connectionId: string, streamId?: string) {
        let connection = this.createPeerConnection(connectionId);
        if (this.localMediaStream) {
            this.localMediaStream.getTracks().forEach(track => connection.addTrack(track));
        }
        let offer = await connection.createOffer({ offerToReceiveAudio: false, offerToReceiveVideo: false });
        await connection.setLocalDescription(offer);
        return offer;
    }

    async createAnswer(connectionId: string, offer: any, onlyReceive?: boolean) {
        const connection = this.createPeerConnection(connectionId);
        await connection.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await connection.createAnswer();
        await connection.setLocalDescription(answer);
        this.processCandidates();

        return answer;
    }

    async receivedAnswer(connectionId: string, answer: any) {
        console.log('receivedAnswer', connectionId);
        const connection = this.peerConnections[connectionId];
        if (connection) {
            console.log('connection found');
            console.log('add remoteDescription');
            await connection.setRemoteDescription(new RTCSessionDescription(answer));
            this.processCandidates();
        }
    }

    async receivedIceCandidate(connectionId: string, iceCandidate: any) {
        console.log('receivedIceCandidate', iceCandidate);
        const connection = this.peerConnections[connectionId];
        if (connection) {

            if (connection.remoteDescription == null || this.remoteCandidates.length > 0) {
                this.remoteCandidates.push({
                    connectionId: connectionId,
                    iceCandidate: iceCandidate,
                });
                return;
            }
            try {
                await connection.addIceCandidate(iceCandidate);
            }
            catch (ex) {
                console.log(ex);
            }
        }
        else {
            this.remoteCandidates.push({
                connectionId: connectionId,
                iceCandidate: iceCandidate,
            });
        }
    }

    async processCandidates() {
        console.log('!!!processCandidates', this.remoteCandidates.length);
        for (let i = 0; i < this.remoteCandidates.length; i++) {
            let candidate = this.remoteCandidates[i];
            try {
                await this.peerConnections[candidate.connectionId].addIceCandidate(
                    candidate.iceCandidate,
                );
            }
            catch (ex) {
                console.log(ex);
            }
        }
        this.remoteCandidates = [];
    }

    async shareScreen() {
        /* = uid.rnd();*/

        try {
            // @ts-ignore
            this.localMediaStream = await navigator.mediaDevices.getDisplayMedia({cursor: true, video: {resizeMode: 'none', width: 3840, height: 2160}});
            if (this.localMediaStream) {
                return true;
            }
            return false;
        }
        catch (ex) {
            return false;
        }
    }

    async stopStream() {
        if (this.localMediaStream) {
            console.log('stopStream');
            if (this.localMediaStream) {
                this.localMediaStream.getTracks().forEach(track => track.stop());
            }
        }
    }
}

const callController = new CallController();
export default callController;
