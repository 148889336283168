import {setServiceCode, setViewMode} from '../store/actions/view';
import {store} from '../store';
import connectionController from '../controllers/ConnectionController';
import callController from '../controllers/CallController';

export async function getServiceCode() {
    const code = "abcdef";

    let participants: {}[] = [{}, {}];
    let result = await connectionController.sendCommand('createChatRoom', {participants: participants, appType: 'apozinConferenz', withShortCode: true})

    console.log('result', result);

    if (result.errorCode === 0) {
        store.dispatch(setServiceCode(result.shortCodes[1]));
        await connectionController.enterChatRoomWithShortCode(result.shortCodes[0]);
    }

    store.dispatch(setViewMode('spWaiting'));
}
