import React, {useState} from 'react';
import './App.css';
import Header from './components/views/Header';
import StdButton from './components/buttons/StdButton';
import {useSelector} from 'react-redux';
import {RootState} from './store';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import {getServiceCode} from './functions/serviceProvider';
import BorderView from './components/views/BorderView';
import StdText from './components/texts/StdText';
import StreamView from './components/views/StreamView';
import {Fade} from '@mui/material';
import Footer from './components/views/Footer';
import logoServiceProvider from './assets/images/logoServiceProvider.png';
import copy from './assets/images/copy.png';

function AppServiceProvider() {
    const viewMode = useSelector((state: RootState) => state.view.viewMode);
    const serviceCode = useSelector((state: RootState) => state.view.serviceCode);
    const remoteMediaStream = useSelector((state: RootState) => state.view.remoteMediaStream);
    const [copied, setCopied] = useState(false);
    return (
        <div className="App">
            <Header/>
            {viewMode !== 'spMediaStream' &&
                <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={logoServiceProvider} alt="logo" width={169} height={153} style={{marginLeft: 40, position: 'absolute'}}/>
                </div>
            }
            <div style={{flex: 1, alignItems: 'flex-start', justifyContent: 'center', display: 'flex', position: 'relative'}}>
                    <Slide in={viewMode === 'spStart'} appear={false} direction={'right'} mountOnEnter unmountOnExit>
                        <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                            <StdButton text={'ServiceCode erstellen und verbinden'} onClick={() => {
                                getServiceCode();
                            }}/>
                        </Paper>
                    </Slide>
                <Slide in={viewMode === 'spWaiting'} appear={true} direction={'right'} mountOnEnter unmountOnExit>
                    <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                        <BorderView style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <StdText type={'header'}>Service-Code</StdText>
                            <div style={{position: 'relative', borderWidth: 1, borderRadius: 8, borderColor: '#0055A2', borderStyle: 'solid', marginTop: 16, marginBottom: 16, height: 52, width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <span style={{fontSize: 28, color: '#189B00'}}>{serviceCode}</span>
                                <img src={copy} alt="logo" width={27} height={30} style={{right: 12, position: 'absolute', padding: 4, cursor: 'pointer'}} onClick={() => {
                                    if (serviceCode) {
                                        navigator.clipboard.writeText(serviceCode);
                                        setCopied(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 3000)
                                    }
                                }}/>
                                <Fade in={copied}><Paper elevation={0} style={{position: 'absolute', right: -64}}><StdText type={'normalText'}>Kopiert</StdText></Paper></Fade>
                            </div>
                            <StdText type={'header'}>{'Geben Sie diesen Code an Ihren Kunden weiter'}</StdText>
                            <StdButton style={{marginTop: 24}} inverted={true} text={'Service-Code erneut erstellen'} onClick={() => {
                                getServiceCode();
                            }}/>
                        </BorderView>
                    </Paper>
                </Slide>
                <Fade in={viewMode === 'spMediaStream'} appear={true} mountOnEnter unmountOnExit>
                    <Paper elevation={0} style={{background: 'transparent', position: 'absolute', top: 0, bottom: 4, left: 0, right: 0}}>
                        <StreamView stream={remoteMediaStream}/>
                    </Paper>
                </Fade>
                <Slide in={viewMode === 'spClosed'} appear={true} direction={'right'} mountOnEnter unmountOnExit>
                    <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                        <BorderView><StdText type={'header'}>Bildschirmteilen wurde beendet.</StdText></BorderView>
                    </Paper>
                </Slide>
            </div>
            {viewMode !== 'spMediaStream' && <Footer/>}
        </div>
    );
}

export default AppServiceProvider;
