import React from 'react';
import logo from '../../assets/images/apozinLogo.png';
import StdText from '../texts/StdText';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';

interface IProps {
}

const Header: React.FC<IProps> = (props: IProps) => {
    const connectionStatus = useSelector((state: RootState) => state.connection.connectionStatus);

    let statusColor = '#808080';
    let statusText = 'Verbinde';
    if (connectionStatus === 'connected') {
        statusColor = '#70EF59';
        statusText = 'Verbunden';
    }
    else if (connectionStatus === 'disconnected') {
        statusColor = '#fc2403';
        statusText = 'Keine Verbindung';
    }

    return (
        <div style={{height: 50, display: 'flex', backgroundColor: '#000000', padding: 10, alignItems: 'center'}}>
            <img src={logo} alt="logo" width={150} height={39} style={{marginLeft: 40, position: 'absolute'}}/>

            <div style={{position: 'absolute', right: 12, flexDirection: 'row', display: 'flex'}}><StdText type={'boldText'}>Server status<span style={{color: statusColor, marginLeft: 20, marginRight: 12}}>{statusText}</span></StdText><div style={{height: 20, width: 20, backgroundColor: statusColor, marginRight: 40, borderRadius: 10}}></div></div>
        </div>
    );
}

export default Header;
