import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppCustomer from './AppCustomer';
import reportWebVitals from './reportWebVitals';
import AppServiceProvider from './AppServiceProvider';
import {Provider} from 'react-redux';
import {store} from './store';
import connectionController from './controllers/ConnectionController';
import {setConnectionStatus} from './store/actions/connection';
import callController from './controllers/CallController';
import {setRemoteMediaStream, setViewMode} from './store/actions/view';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

console.log('window.location.pathname', window.location.href);

if (window.location.href.endsWith("#customer")) {

    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
        store.dispatch(setViewMode('cuUnsupportedBrowser'));
    }

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <AppCustomer />
            </Provider>
        </React.StrictMode>
    );
}

if (window.location.href.endsWith("#serviceprovider")) {
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <AppServiceProvider />
            </Provider>
        </React.StrictMode>
    );
}

connectionController.onConnected = () => {
    store.dispatch(setConnectionStatus('connected'));
}
connectionController.onDisconnected = () => {
    store.dispatch(setConnectionStatus('disconnected'));
}
connectionController.connect();

callController.on('remoteStream', (data) => {
    console.log('remoteStream!', data);
    store.dispatch(setRemoteMediaStream(data.remoteStream));
});

callController.on('remoteStreamTrack', (data) => {
    console.log('remoteStreamTrack!', data);
    const mediaStream = store.getState().view.remoteMediaStream;
    if (mediaStream) {
        mediaStream.addTrack(data.track);
        store.dispatch(setViewMode('spMediaStream'));
    }
});

callController.on('icecandidate', (data) => {
    connectionController.sendMessageToChatRoomMember(connectionController.otherMemberId, {
        type: 'iceCandidate',
        candidate: data.candidate,
        streamId: data.connectionId,
    });
});

callController.on('callClosed', (data) => {
    store.dispatch(setViewMode('spClosed'));
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
