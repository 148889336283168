import {createReducer} from '@reduxjs/toolkit';
import {setRemoteMediaStream, setServiceCode, setViewMode} from '../actions/view';

export type ViewMode = 'spStart' | 'spWaiting' | 'spMediaStream' | 'spClosed' | 'cuStart' | 'cuAccept' | 'cuSharing' | 'cuSharingDenied' | 'cuUnsupportedBrowser';

export interface ViewState {
    viewMode: ViewMode;
    serviceCode?: string;
    remoteMediaStream?: MediaStream;
}

const defaultState: ViewState = {
    viewMode: window.location.href.endsWith("#customer") ? 'cuStart' : 'spStart'
};

export const viewReducer = createReducer(defaultState, (builder) =>
    builder.addCase(setViewMode, (state, action) => {
        state.viewMode = action.payload
    }).addCase(setServiceCode, (state, action) => {
        state.serviceCode = action.payload
    }).addCase(setRemoteMediaStream, (state, action) => {
        state.remoteMediaStream = action.payload
    })
);
