import React, {CSSProperties, useEffect, useRef} from 'react';

interface IProps {
    stream?: MediaStream;
    style?: CSSProperties;
    muted?: boolean;
}

const StreamView: React.FC<IProps> = (props: IProps) => {
    const videoRef = useRef(null as (HTMLVideoElement | null));

    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.srcObject = props.stream || null;
        }
    }, [videoRef, props.stream])

    return (
        <>
        <video autoPlay={true} playsInline={true} controls={false} muted={props.muted}
               style={{...{
                   height: '100%',
                   background: '#000000',
                       borderRadius: 12,
               }, ...props.style}} ref={videoRef}/>
        </>
    );
}

export default StreamView;
