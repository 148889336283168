import React, {useState} from 'react';
import './App.css';
import Header from './components/views/Header';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import BorderView from './components/views/BorderView';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from './store';
import StdButton from './components/buttons/StdButton';
import {enterWithServiceCode} from './functions/customer';
import {setViewMode} from './store/actions/view';
import connectionController from './controllers/ConnectionController';
import Footer from './components/views/Footer';
import logoCustomer from './assets/images/logoCustomer.png';

function AppCustomer() {
    const viewMode = useSelector((state: RootState) => state.view.viewMode);
    const [serviceCode, setServiceCode] = useState('');
    const dispatch = useDispatch();
  return (
    <div className="App">
      <Header/>
        <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src={logoCustomer} alt="logo" width={169} height={153} style={{marginLeft: 40, position: 'absolute'}}/>
        </div>
        <div style={{flex: 1, alignItems: 'flex-start', justifyContent: 'center', display: 'flex'}}>
            <Slide in={viewMode === 'cuStart'} appear={true} direction={'right'} mountOnEnter unmountOnExit>
                <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                    <BorderView style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>Bitte tragen Sie Ihren ServiceCode ein{'\n\n'}
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <input type={'text'} className={'StdInput'} style={{position: 'relative', borderWidth: 1, borderRadius: 8, borderColor: '#0055A2', borderStyle: 'solid', fontSize: 28, marginTop: 16, marginBottom: 16, height: 52, width: 300, color: '#189B00', display: 'flex', alignItems: 'center', outline: 0, justifyContent: 'center', textAlign: 'center', fontWeight: 'bold'}} placeholder={'ServiceCode'}
                            onChange={(newValue) => {setServiceCode(newValue.currentTarget.value);}}/>
                            <div style={{width: 216, height: 4, backgroundColor: '#ffffff', borderRadius: 2}}></div>
                        </div>
                        <StdButton style={{marginTop: 8, marginBottom: 8, width: 284}} text={'Senden'} onClick={() => {
                            enterWithServiceCode(serviceCode);
                        }} />
                    </BorderView>
                </Paper>
            </Slide>
            <Slide in={viewMode === 'cuAccept'} appear={true} direction={'right'} mountOnEnter unmountOnExit>
                <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                    <BorderView style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>Bitte suchen Sie Ihren Bildschirmausschnitt aus,{'\n'}den Sie freigeben möchten.
                    </BorderView>
                </Paper>
            </Slide>
            <Slide in={viewMode === 'cuSharingDenied'} appear={true} direction={'right'} mountOnEnter unmountOnExit>
                <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                    <BorderView style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>Sie haben das Bildschirmteilen abgelehnt.
                        <StdButton style={{marginTop: 20, marginBottom: 8}} text={'Erneut anfragen'} onClick={() => {
                            enterWithServiceCode(serviceCode);
                        }} />
                    </BorderView>
                </Paper>
            </Slide>
            <Slide in={viewMode === 'cuSharing'} appear={true} direction={'right'} mountOnEnter unmountOnExit>
                <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                    <BorderView style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>Ihr Bildschirmausschnitt wird geteilt.
                        <StdButton style={{marginTop: 20, marginBottom: 8}} text={'Teilen beenden'} onClick={async () => {
                            await connectionController.closeCall();


                            dispatch(setViewMode('cuStart'));
                        }} />
                    </BorderView>
                </Paper>
            </Slide>
            <Slide in={viewMode === 'cuUnsupportedBrowser'} appear={true} direction={'right'} mountOnEnter unmountOnExit>
                <Paper elevation={0} style={{background: 'transparent', position: 'absolute'}}>
                    <BorderView style={{display: 'flex', alignItems: 'center', flexDirection: 'column', color: '#DE6262'}}>Sie nutzen den Safari Browser, leider unterstützen wir diesen nicht.{'\n'}Verwenden Sie bitte Google Chrome.
                    </BorderView>
                </Paper>
            </Slide>
        </div>
        <Footer/>
    </div>
  );
}

export default AppCustomer;
