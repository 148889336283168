import {setServiceCode, setViewMode} from '../store/actions/view';
import {store} from '../store';
import connectionController from '../controllers/ConnectionController';
import callController from '../controllers/CallController';

export async function enterWithServiceCode(serviceCode: string) {
    let participants: {}[] = [{}, {}];

    if (await connectionController.enterChatRoomWithShortCode(serviceCode)) {
        store.dispatch(setViewMode('cuAccept'));

        let sharing = await callController.shareScreen();

        if (sharing) {
            store.dispatch(setViewMode('cuSharing'));
            await connectionController.callOtherMember();
        } else {
            store.dispatch(setViewMode('cuSharingDenied'));
        }
    }
}
