import React from 'react';

interface IProps {
}

const Footer: React.FC<IProps> = (props: IProps) => {
    return (
        <div style={{height: 50, display: 'flex', backgroundColor: '#000000', padding: 10, alignItems: 'center'}} />
    );
}

export default Footer;
