import React, {CSSProperties} from 'react';

interface IProps {
    children?: React.ReactNode;
    style?: CSSProperties;
}

const BorderView: React.FC<IProps> = (props: IProps) => {
    return (
        <div style={{...{whiteSpace: 'pre-line', color: '#0055A2', borderWidth: 4, borderColor: '#ffffff', borderStyle: 'solid', padding: 12, borderRadius: 8, fontWeight: 'bold', fontSize: 24}, ...props.style}}>{props.children}</div>
    );
}

export default BorderView;
