import {createReducer} from '@reduxjs/toolkit';
import {setConnectionStatus} from '../actions/connection';

export type ConnectionStatus = 'unknown'|'connected'|'disconnected';

export interface ConnectionState {
    connectionStatus: ConnectionStatus;
}

const defaultState: ConnectionState = {
    connectionStatus: 'unknown'
};

export const connectionReducer = createReducer(defaultState, (builder) =>
    builder.addCase(setConnectionStatus, (state, action) => {
        state.connectionStatus = action.payload
    })
);
